import React from 'react';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';

const PostPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    Blog post
  </Layout>
);

export default PostPage;
